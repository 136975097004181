import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { useState } from "react";
import styles from "./styles.module.scss";
import UserProfile from "../../assets/user-profile.svg";
import DownArrow from "../../assets/down-arrow.svg";
import UpArrow from "../../assets/up-arrow.svg";
import LeftArrow from "../../assets/left-arrow.svg";
import { Dropdown } from "react-bootstrap";
import BreadCrumbs from "../../components/Breadcrumb";
import Button from "components/Button";
import { useMsal } from "@azure/msal-react";

interface AppNavbarProps {
  title: string;
  description?: string;
  children: React.ReactElement;
  isGoBack?: boolean;
}

export default function AppNavbar({
  title,
  description,
  children,
  isGoBack,
}: AppNavbarProps) {
  const [menuOpen, setMenuOpen] = useState(false);

  const { instance, accounts } = useMsal();

  const onLogout = () => {
    instance.logoutRedirect({
      account: accounts[0],
    });
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  return (
    <div className={styles.container} data-testid="container">
      <div className={styles.headerArea} data-testid="headerArea">
        <div className={styles.header} data-testid="header">
          <Dropdown className={styles.dropdown} data-testid="dropdown">
            <Dropdown.Toggle className={styles.profileArea}>
              <div
                className={styles.profileArea}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <img alt="" src={UserProfile} />
                <img alt="" src={menuOpen ? UpArrow : DownArrow} />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.containerDropdown}>
              <div className={styles.row}>
                <Button
                  testId="include-new-base-button"
                  onClick={() => onLogout()}
                >
                  <span>Sair</span>
                </Button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={styles.contentContainer} data-testid="contentContainer">
        <div className={styles.breadcrumbArea} data-testid="breadcrumbArea">
          <BreadCrumbs />
        </div>
        <div className={styles.titlePageArea} data-testid="titlePageArea">
          {isGoBack && (
            <div
              className={styles.backButton}
              onClick={() => window.history.back()}
              data-testid="backButton"
            >
              <img alt="" src={LeftArrow} />
            </div>
          )}
          <span className={styles.titlePage} data-testid="titlePage">
            {title}
          </span>
        </div>
        {description && (
          <div className={styles.descriptionArea} data-testid="descriptionArea">
            <span className={styles.description} data-testid="descriptionText">
              {description}
            </span>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
